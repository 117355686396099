import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
// utils
import localStorageAvailable from "../utils/localStorageAvailable"
//
import Backend from "i18next-http-backend"

// ----------------------------------------------------------------------

let lng = 'fr'

const storageAvailable = localStorageAvailable()

if (storageAvailable) {
  lng = localStorage.getItem("i18nextLng") || 'fr'
}

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng,
    fallbackLng: 'fr',
    debug: true,
    ns: ["mobilio"],
    defaultNS: "mobilio",
    interpolation: {
      escapeValue: false,
    },
    react: { 
      useSuspense: false //   <---- this will do the magic
    },
    saveMissing: true,
    backend: {
      loadPath: `${process.env.REACT_APP_BACK_I18N}/i18n/{{lng}}/{{ns}}`,
      addPath: `${process.env.REACT_APP_BACK_I18N}/i18n/{{lng}}/{{ns}}`,
      parsePayload: function(namespace, key, fallbackValue) { return { name: key, value: fallbackValue } },
    },
    initImmediate: false // Rendre l'initialisation synchrone
  })

export default i18n
