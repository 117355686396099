import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { LanguageContext } from '../contexts/LanguageContext'
// @mui
// ----------------------------------------------------------------------

export default function useLocales(): any {
  const { i18n, t: translate } = useTranslation()
  const { languages, loading } = useContext(LanguageContext)

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang)
  }

  const currentLang = languages.find((_lang) => _lang.lang === localStorage.getItem('i18nextLng')) || languages[0]

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: languages,
    defaultLang: languages[0],
    loading,
  }
}
