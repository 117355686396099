import React, { useState } from 'react'
// @mui
import { MenuItem, Stack } from '@mui/material'
// hooks
import useLocales from '../../../hooks/useLocales'
// components
import MenuPopover from '../../../components/MenuPopover'
import { IconButtonAnimate } from '../../../components/animate'

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const { allLang, currentLang, onChangeLang, translate: t } = useLocales()
  const [open, setOpen] = useState<HTMLElement | null>(null)

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && { bgcolor: 'action.selected' }),
        }}
      >
        <span title={currentLang.label}>{currentLang.flag}</span>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {allLang.map((option) => (
            <MenuItem
              key={option.lang}
              selected={option.lang === currentLang.lang}
              onClick={() => {
                onChangeLang(option.lang)
                handleClose()
              }}
            >
              <Stack direction="row" spacing={0.75}>
                <span title={option.label}>{option.flag}</span>
                <span>{t(option.label)}</span>
              </Stack>
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  )
}
