import React, { createContext, useState, useEffect, ReactNode } from 'react'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

interface LanguageContextProps {
  languages: any[];
  loading: boolean;
}

export const LanguageContext = createContext<LanguageContextProps>({
  languages: [],
  loading: true,
})

export const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const [languages, setLanguages] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
    const { i18n } = useTranslation()

  useEffect(() => {
    async function fetchLanguages() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACK_I18N}/i18n/languages`)
        setLanguages(response.data)
      } catch (error) {
        console.error('Failed to fetch languages:', error)
        setLanguages([{ label: 'Français', lang: 'fr', flag:'🇫🇷' }]) // Utiliser le français comme langue par défaut
      } finally {
        setLoading(false)
      }
    }

    fetchLanguages()
  }, [])

  useEffect(() => {
    if (!loading) {
      const langStorage = localStorage.getItem('i18nextLng')
      const defaultLang = languages.find(lang => lang.value === langStorage) || languages[0]
      if (defaultLang) {
        i18n.changeLanguage(defaultLang.value)
      }
    }
  }, [loading, languages, i18n])

  return (
    <LanguageContext.Provider value={{ languages, loading }}>
      {loading ? 'Loading...' : children}
    </LanguageContext.Provider>
  )
}
