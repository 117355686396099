// import { Quote } from '../@types/quote'
import slugify from 'slugify'
import useLocales from '../hooks/useLocales'

// Récupère la route pour accéder au PDF back
// Nom du PDF que l'on peut télécharger
export const getQuotePDFFileName = (data) => {
  const fileName = slugify(`${data} ${data.first_name} ${data.last_name}`, {
    replacement: '_',
    lower: true,
  })
  const relativeFilePath = `${fileName}.pdf`
  return relativeFilePath
}

// Serveur, route, id du PDF, création de la route dans la back
export const getQuotePDFURI = (data, lang: string) =>
  `${process.env.REACT_APP_SERVER_URL}/api/pdf/quote/${lang}/${data}`

export const getAllQuotePDFURI = () => `${process.env.REACT_APP_SERVER_URL}/api/pdf/quote/`

// Not in use
export const getDepositBillPDFFileName = (data) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { translate: t } = useLocales()
  const fileName = slugify(
    `${data} ${t(`bill.deposit_bill`)} ${data.first_name} ${data.last_name}`
  ).toLowerCase()
  const relativeFilePath = `${fileName}.pdf`
  return relativeFilePath
}

export const getDepositBillPDFURI = (data, lang: string) =>
  `${process.env.REACT_APP_SERVER_URL}/api/pdf/deposit-bill/${lang}/${data}`

// Not in use
export const getBalanceBillPDFFileName = (data) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { translate: t } = useLocales()
  const fileName = slugify(
    `${data} ${t(`bill.balance_bill`)} ${data.first_name} ${data.last_name}`
  ).toLowerCase()
  const relativeFilePath = `${fileName}.pdf`
  return relativeFilePath
}

export const getBalanceBillPDFURI = (data, lang: string) =>
  `${process.env.REACT_APP_SERVER_URL}/api/pdf/balance-bill/${lang}/${data}`

export const getWaybillPDFURI = (data, lang: string) =>
  `${process.env.REACT_APP_SERVER_URL}/api/pdf/waybill/${lang}/${data}`
