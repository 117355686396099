// routes
import { PATH_DASHBOARD } from '../../../routes/paths'
// components
import SvgIconStyle from '../../../components/SvgIconStyle'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import ArticleIcon from '@mui/icons-material/Article'
import DashboardIcon from '@mui/icons-material/Dashboard'
import EventIcon from '@mui/icons-material/Event'
import ForkRightIcon from '@mui/icons-material/ForkRight'
import LocalParkingIcon from '@mui/icons-material/LocalParking'
import StorefrontIcon from '@mui/icons-material/Storefront'

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
)

const ICONS = {
  document: <InsertDriveFileOutlinedIcon />,
  quote: <ArticleIcon />,
  user: getIcon('ic_user'),
  dashboard: <DashboardIcon />,
  planning: <EventIcon />,
  route: <ForkRightIcon />,
  parking: <LocalParkingIcon />,
  market: <StorefrontIcon />,
}
const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'menu.quotes',
        path: PATH_DASHBOARD.general.quotes,
        icon: ICONS.quote,
      },
      {
        title: 'menu.pdfs',
        path: PATH_DASHBOARD.general.documents,
        icon: ICONS.document,
      },
      {
        title: 'menu.dashboard',
        path: PATH_DASHBOARD.dashboard,
        icon: ICONS.dashboard,
        comingSoon: false,
      },
      {
        title: 'menu.planning',
        path: PATH_DASHBOARD.planning,
        icon: ICONS.planning,
        comingSoon: true,
      },
      {
        title: 'menu.route_optimization',
        path: PATH_DASHBOARD.routeOptimization,
        icon: ICONS.route,
        comingSoon: true,
      },
      {
        title: 'menu.parking_request',
        path: PATH_DASHBOARD.parkingRequest,
        icon: ICONS.parking,
        comingSoon: true
      },
      {
        title: 'menu.market_place',
        path: PATH_DASHBOARD.marketPlace,
        icon: ICONS.market,
        comingSoon: true,
      },
    ],
  },
]

export default navConfig
